<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="群名称：">
        <el-input v-model="name" size="small"></el-input>
      </el-form-item>
      <el-form-item label="群主名称：">
        <el-input v-model="user_name" size="small"></el-input>
      </el-form-item>
      <el-form-item label="创建时间：">
        <el-date-picker @change="getTime" v-model="time" size="small" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="cancel">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="群名称" align="center"></el-table-column>
      <el-table-column prop="owner" label="群主名称" align="center"></el-table-column>
      <el-table-column prop="member_count" label="群人数" align="center"></el-table-column>
      <el-table-column prop="click_num" label="点击次数" align="center"></el-table-column>
      <el-table-column prop="create_time" label="创建时间" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button @click="$router.push({ path: '/user/enterpriseWechat/teamManageDetail', query: { chat_id: row.chat_id } })" type="text" size="small">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '../../../components/paging.vue';
import { getDateformat } from '../../../util/getDate';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      page: 1,
      rows: 10,
      total_number: 0,
      list: [],
      name: '',
      user_name: '',
      start_time: '',
      end_time: '',
      time: '',
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getList();
    },
    getTime(val) {
      if (val) {
        this.start_time = val[0].getTime() / 1000;
        this.end_time = val[1].getTime() / 1000;
      } else {
        this.start_time = '';
        this.ent_time = '';
      }
    },
    search() {
      this.page = 1;
      this.getList();
    },
    cancel() {
      this.name = '';
      this.user_name = '';
      this.start_time = '';
      this.end_time = '';
      this.time = '';
      this.getList();
    },
    getList() {
      let data = {
        page: this.page,
        rows: this.rows,
      };
      if (this.name) data.name = this.name;
      if (this.user_name) data.user_name = this.user_name;
      if (this.time) {
        data.start_time = this.start_time;
        data.end_time = this.end_time;
      }
      this.$axios
        .post(this.$api.user.wxwork.groupchatList, data)
        .then(res => {
          if (res.code == 0) {
            this.list = res.result.groupchat_list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>
